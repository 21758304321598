import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import Editor from 'src/components/editor/editor';
import { DatePicker } from '@mui/x-date-pickers';
import {
  createSystemNotifyCMSApi,
  getSystemNotifyDetailCMSApi,
  updateSystemNotifyCMSApi,
} from 'src/api/admin/system-notify.api';
import { fDate } from 'src/utils/format-time';
import { useBoolean } from 'src/hooks/use-boolean';
import SystemNotifyPreviewDialog from './system-notify-preview-dialog';

export default function CreateUpdateNotifyDialog({ open, onClose, reloadData, notifyId }) {
  const preview = useBoolean();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [dateExpired, setDateExpired] = useState(null);
  const [errorValidate, setErrorValidate] = useState({
    title: '',
    content: '',
    dateExpired: '',
  });
  const [errorMsg, setErrorMsh] = useState('');
  const [loading, setLoading] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let continueSubmit = true;

      if (!title) {
        setErrorValidate((prev) => ({ ...prev, title: 'Vui lòng nhập tiêu đề!' }));
        continueSubmit = false;
      }
      if (!content) {
        setErrorValidate((prev) => ({ ...prev, content: 'Vui lòng nhập nội dung!' }));
        continueSubmit = false;
      }
      if (!!dateExpired && new Date(dateExpired) < new Date()) {
        setErrorValidate((prev) => ({ ...prev, dateExpired: 'Ngày hết hạn không hợp lệ!' }));
        continueSubmit = false;
      }

      if (!continueSubmit) {
        return;
      }

      const payload = {
        title,
        content,
        ...(dateExpired && { date_expired: fDate(dateExpired, 'yyyy-MM-dd') }),
      };

      if (notifyId) {
        await updateSystemNotifyCMSApi(notifyId, payload);
      } else {
        await createSystemNotifyCMSApi(payload);
      }
      reloadData();
      enqueueSnackbar(notifyId ? 'Cập nhật thông báo thành công!' : 'Tạo thông báo thành công!', {
        variant: 'success',
      });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error?.error_code === 403) {
        handleClose();
        enqueueSnackbar('Bạn không có quyền thực hiện hành động này!', { variant: 'error' });
      } else {
        setErrorMsh(notifyId ? 'Cập nhật thông báo thất bại!' : 'Tạo thông báo thất bại!');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setErrorValidate({});
    setErrorMsh('');
    setTitle('');
    setContent('');
    setDateExpired(null);
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (notifyId) {
          const response = await getSystemNotifyDetailCMSApi(notifyId);
          if (response?.data) {
            setTitle(response?.data?.title);
            setContent(response?.data?.content);
            setDateExpired(
              !response?.data?.date_expired
                ? response?.data?.date_expired
                : new Date(response?.data?.date_expired)
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (open) {
      fetchData();
    }
  }, [notifyId, open]);

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open}>
        <DialogTitle
          variant="h6"
          sx={{
            pb: 0,
          }}
        >
          {notifyId ? 'Cập nhật thông báo' : 'Tạo thông báo'}
        </DialogTitle>
        <DialogContent
          sx={{
            pt: '16px!important',
          }}
        >
          {!!errorMsg && (
            <Alert
              severity="error"
              sx={{
                mb: 3,
              }}
            >
              {errorMsg}
            </Alert>
          )}
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="Tiêu đề"
              placeholder="Nhập tiêu đề thông báo"
              error={!!errorValidate.title}
              helperText={errorValidate.title}
              value={title}
              onChange={(event) => {
                if (errorMsg) {
                  setErrorMsh('');
                }
                if (errorValidate) {
                  setErrorValidate((prev) => ({ ...prev, title: '' }));
                }
                setTitle(event.target.value);
              }}
            />

            <DatePicker
              open={openPicker}
              onClose={() => setOpenPicker(false)}
              label="Ngày hết hạn"
              value={dateExpired}
              onChange={(newValue) => {
                if (errorMsg) {
                  setErrorMsh('');
                }
                if (errorValidate) {
                  setErrorValidate((prev) => ({ ...prev, dateExpired: '' }));
                }
                setDateExpired(newValue);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  placeholder: 'Ngày/Tháng/Năm',
                  onClick: () => setOpenPicker(true),
                  error: !!errorValidate.dateExpired,
                  helperText: errorValidate.dateExpired,
                },
              }}
              sx={{
                width: 'fit-content',
                '& svg': {
                  zIndex: 1,
                },
              }}
            />

            <Stack>
              <Editor
                id="content"
                value={content}
                onChange={(value) => {
                  if (errorMsg) {
                    setErrorMsh('');
                  }
                  if (errorValidate) {
                    setErrorValidate((prev) => ({ ...prev, content: '' }));
                  }
                  setContent(value);
                }}
                error={!!errorValidate.content}
                helperText={
                  <FormHelperText error={!!errorValidate.content} sx={{ px: 2 }}>
                    {errorValidate.content ? errorValidate.content : ''}
                  </FormHelperText>
                }
                placeholder="Nhập nội dung thông báo"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <Stack direction="row" spacing={2} justifyContent="flex-end" py={3} pr={3}>
          <Button variant="outlined" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="contained" onClick={preview.onTrue}>
            Xem trước
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {notifyId ? 'Cập nhật' : 'Tạo'}
          </LoadingButton>
        </Stack>
      </Dialog>
      <SystemNotifyPreviewDialog
        open={preview.value}
        onClose={preview.onFalse}
        title={title}
        content={content}
      />
    </>
  );
}

CreateUpdateNotifyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reloadData: PropTypes.func,
  notifyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
