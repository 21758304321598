import {
  format,
  getTime,
  formatDistanceToNow,
  getYear,
  isSameDay,
  isSameMonth,
  parseISO,
} from 'date-fns';
import { vi } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: vi,
      })
    : '';
}

export function shortDateLabel(startDate, endDate) {
  const getCurrentYear = new Date().getFullYear();

  // Ensure dates are parsed if they are strings
  const parsedStartDate = startDate ? parseISO(startDate) : null;
  const parsedEndDate = endDate ? parseISO(endDate) : null;

  if (startDate && !endDate) {
    return `Bắt đầu ${fDate(parsedStartDate, 'dd/MM/yy')}`;
  }

  if (!startDate && endDate) {
    return `Kết thúc ${fDate(parsedEndDate, 'dd/MM/yy')}`;
  }

  const startDateYear = parsedStartDate ? getYear(parsedStartDate) : null;

  const endDateYear = parsedEndDate ? getYear(parsedEndDate) : null;

  const currentYear = getCurrentYear === startDateYear && getCurrentYear === endDateYear;

  const sameDay = startDate && endDate ? isSameDay(parsedStartDate, parsedEndDate) : false;

  const sameMonth = startDate && endDate ? isSameMonth(parsedStartDate, parsedEndDate) : false;

  if (currentYear) {
    if (sameMonth) {
      if (sameDay) {
        return fDate(parsedEndDate, 'dd/MM/yy');
      }
      return `${fDate(parsedStartDate, 'dd')} - ${fDate(parsedEndDate, 'dd/MM/yy')}`;
    }
    return `${fDate(parsedStartDate, 'dd/MM')} - ${fDate(parsedEndDate, 'dd/MM/yy')}`;
  }

  return `${fDate(parsedStartDate, 'dd/MM/yy')} - ${fDate(parsedEndDate, 'dd/MM/yy')}`;
}
