import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Editor from 'src/components/editor/editor';
import { getSystemNotifyApi, hideSystemNotifyApi } from 'src/api/client/system-notify.api';

export default function SystemNotifyDialog() {
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState({});
  const [hide, setHide] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:740px)');

  const handleClose = async () => {
    setOpen(false);
    if (hide) {
      try {
        await hideSystemNotifyApi(notify?._id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    let timer;

    const handleFetchNotify = async () => {
      try {
        const response = await getSystemNotifyApi();
        const { data } = response.data;
        if (data) {
          setNotify(data);
          setOpen(true);
        }
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
        setOpen(false);
      } finally {
        timer = setTimeout(handleFetchNotify, 300000);
      }
    };

    handleFetchNotify();

    return () => clearTimeout(timer);
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiPaper-root.MuiPaper-elevation': {
          boxShadow: theme.customShadows.z4,
        },
      }}
    >
      <DialogContent
        sx={{
          height: '540px',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: `url("/assets/background/notification.png")`,
          position: 'relative',
          p: 0,
        }}
      >
        <Stack
          zIndex={1}
          color="black"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: 180,
            left: matches ? 140 : 20,
            right: matches ? 140 : 20,
            bottom: 136,
            textAlign: 'justify',
          }}
        >
          <Typography variant="h3" color="primary" textAlign="center">
            {notify?.title}
          </Typography>
          <Editor
            sx={{
              backgroundColor: 'transparent',
              '& .ql-editor': {
                p: 0,
                backgroundColor: 'transparent',
                maxHeight: 'fit-content',
              },
              width: 1,
              border: 'none',
              height: 'calc(100% - 44px)',
              overflow: 'auto',
            }}
            id="simple-editor"
            value={notify?.content}
            placeholder="Nội dung thông báo..."
            readOnly
          />
        </Stack>
      </DialogContent>
      <IconButton
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          boxShadow: theme.customShadows.z8,
          p: 0,
        }}
        onClick={handleClose}
      >
        <Iconify icon="carbon:close-filled" color="white" width={30} />
      </IconButton>
      <FormControlLabel
        sx={{
          position: 'absolute',
          top: 15,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '6px 10px',
          borderRadius: '8px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          color: 'black',
          right: 36,
          '& .MuiButtonBase-root.MuiCheckbox-root': {
            background: 'white',
            borderRadius: '4px',
            p: 0,
            mr: 1,
          },
        }}
        control={<Checkbox checked={hide} onChange={(event) => setHide(event.target.checked)} />}
        label="Không hiển thị nữa"
      />
    </Dialog>
  );
}
