import { useCallback, useMemo, useState } from 'react';
import axiosInstance, { endpoints, fetcher } from 'src/utils/axios';
import { OPTIONS_FETCH } from 'src/utils/constants';
import useSWR, { mutate } from 'swr';

export function useGetSystemNotifyCMSAPi() {
  const URL = endpoints.cms.system_notify.list;
  const [isRefetching, setIsRefetching] = useState(false);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, OPTIONS_FETCH);

  const refetchSystemNotify = useCallback(async () => {
    setIsRefetching(true);
    await mutate(URL);
    setIsRefetching(false);
  }, [URL]);

  const memoizedValue = useMemo(
    () => ({
      systemNotify: data || [],
      systemNotifyLoading: isLoading || isRefetching,
      systemNotifyError: error,
      systemNotifyValidating: isValidating,
      refetchSystemNotify,
    }),
    [data, error, isLoading, isRefetching, isValidating, refetchSystemNotify]
  );

  return memoizedValue;
}
export const createSystemNotifyCMSApi = async (payload) =>
  axiosInstance.post(endpoints.cms.system_notify.create, payload);

export const deleteSystemNotifyCMSApi = async (notifyId) =>
  axiosInstance.delete(endpoints.cms.system_notify.delete(notifyId));

export const updateSystemNotifyCMSApi = async (notifyId, payload) =>
  axiosInstance.put(endpoints.cms.system_notify.update(notifyId), payload);

export const getSystemNotifyDetailCMSApi = async (notifyId) =>
  axiosInstance.get(endpoints.cms.system_notify.detail(notifyId));
